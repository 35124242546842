import React from 'react'
import PropTypes from 'prop-types'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Popover from '@mui/material/Popover'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import { FilterPlus } from 'mdi-material-ui'

import { FirebaseContext } from '../../../utils/firebase'
import 'firebase/auth'

import { useQuery } from 'react-query'

import { API_ROOT_URL } from '../../../constants'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    marginLeft: theme.spacing(2),
  },
  popover: {
    marginTop: theme.spacing(1),
  },
  box: {
    width: 400,
  },
  filterAutocomplete: {
    display: 'block',
  },
  valueAutocomplete: {
    marginTop: theme.spacing(2),
    display: 'block',
  },
  buttonRow: {
    display: 'block',
  },
  cancelButton: {
    float: 'right',
    marginTop: theme.spacing(1),
    color: theme.palette.error.main,
    '&:hover': {
      background: 'rgba(255,23,68,0.04)',
    },
  },
  addButton: {
    float: 'right',
    marginTop: theme.spacing(1),
  },
}))

const filterItems = [
  {id: 'platform', name: 'Platform'},
  {id: 'integration_id', name: 'Data Source'},
  {id: 'funnel_id', name: 'Funnel'},
  {id: 'product_id', name: 'Product'},
  {id: 'productVariant_id', name: 'Product Variant'},
  {id: 'utm_source', name: 'utm_source'},
  {id: 'utm_medium', name: 'utm_medium'},
  {id: 'utm_campaign', name: 'utm_campaign'},
  {id: 'utm_content', name: 'utm_content'},
  {id: 'utm_term', name: 'utm_term'},
  {id: 'affiliate_id_1', name: 'Affiliate ID 1'},
  {id: 'affiliate_id_2', name: 'Affiliate ID 2'},
  {id: 'country', name: 'Country'},
]

const AddFilter = (props) => {
  const classes = useStyles()
  const firebase = React.useContext(FirebaseContext)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [filter, setFilter] = React.useState(null)
  const [filterInput, setFilterInput] = React.useState('')
  const [value, setValue] = React.useState(null)
  const [valueInput, setValueInput] = React.useState('')
  const filterRef = React.useRef()
  const valueRef = React.useRef()

  const { isLoading, data: filterValues } = useQuery(['add-filter', filter], () => {
    if (filter === null) return []
    return firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/fields/${filter.id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(res => res.json())
    })},
    {
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
    }
  )

  React.useEffect(() => {
    if (filter) valueRef.current.focus()
  }, [filter])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setFilterInput('')
    setFilter(null)
    setValueInput('')
    setValue(null)
  }

  const handleSelectFilter = (filter) => {
    setFilter(filter)
    setValueInput('')
    setValue(null)
  }

  const handleSelectValue = (value) => {
    setValue(value)
  }

  const addFilter = () => {
    props.onFilterAdd({...filter, value: value.id, valueName: value.name })
    handleClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'popover-filter' : undefined

  return (
    <div className={classes.root}>
      <Button
        aria-describedby={id}
        variant='outlined'
        color='secondary'
        onClick={handleClick}
        startIcon={<FilterPlus />}
      >
        {props.filterLabel ? props.filterLabel : 'Filter'}
      </Button>
      <Popover
        className={classes.popover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        TransitionProps={{
          onEntered: () => {
            filterRef.current.focus()
          }
        }}>
        <Box className={classes.box} padding={2}>
          <Autocomplete
            className={classes.filterAutocomplete}
            id='filter-autocomplete'
            options={filterItems}
            getOptionDisabled={option => props.filters.findIndex(filter => filter.id === option.id) > -1}
            getOptionLabel={option => option.name}
            value={filter}
            onChange={(_, option) => handleSelectFilter(option)}
            inputValue={filterInput}
            onInputChange={(_, value) => setFilterInput(value)}
            renderInput={(params) => <TextField {...params} inputRef={filterRef} label='Filter By' variant='outlined' />}
            noOptionsText='No matches'
            autoHighlight
            openOnFocus
            fullWidth
          />
          {Boolean(filter) && (
            <React.Fragment>
              <Autocomplete
                className={classes.valueAutocomplete}
                id='value-autocomplete'
                options={filterValues ? filterValues : []}
                getOptionLabel={option => `${option.name}${filter.id === 'product_id' || filter.id === 'funnel_id' || filter.id === 'integration_id' ? ` [${option.platform_id}]` : ''}`}
                filterOptions={createFilterOptions({
                  matchFrom: 'any',
                  stringify: (option) => `[${option.name} ${option.platform_id}]`
                })}
                value={value}
                onChange={(_, option) => handleSelectValue(option)}
                onInputChange={(_, value) => setValueInput(value)}
                inputValue={valueInput}
                renderInput={(params) => (
                  <TextField {...params}
                    inputRef={valueRef}
                    label={'Select ' + filter.name}
                    variant='outlined'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.id} style={{ justifyContent: 'space-between' }}>
                    <Typography variant='body2'>
                      {option.name}
                    </Typography>
                    {(filter.id === 'product_id' || filter.id === 'funnel_id' || filter.id === 'integration_id') && (
                      <Typography variant='caption'>
                        [{option.platform_id}]
                      </Typography>
                    )}
                  </li>
                )}
                loading={isLoading}
                noOptionsText='No matches'
                autoHighlight
                openOnFocus
                fullWidth
              />
              <div className={classes.buttonRow}>
                <Button
                  className={classes.addButton}
                  variant='text'
                  color='secondary'
                  disabled={value === null}
                  onClick={addFilter}
                >
                  Add
                </Button>
                <Button
                  className={classes.cancelButton}
                  variant='text'
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </div>
            </React.Fragment>
          )}
        </Box>
      </Popover>
    </div>
  );
}

AddFilter.propTypes = {
  filters: PropTypes.array.isRequired,
  onFilterAdd: PropTypes.func.isRequired,
  filterLabel: PropTypes.string
}

export default AddFilter
