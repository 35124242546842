import React from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

import SearchInput from '../../Common/SearchInput'
import DateFilterPayments from './DateFilterPayments'
import AmountFilter from './AmountFilter'
import ColumnFilterPayments from './ColumnFilterPayments'
import Exporter from './Exporter'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
  },
  controlForm: {
    marginLeft: theme.spacing(2),
    width: 90,
  },
}))

const ControlsConfigurationPayments = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <SearchInput
        value={props.searchText}
        onChange={props.onSearchTextChange}
        placeholder={'Email Address'}
        addMarginLeft={false}
      />
      <DateFilterPayments
        onFiltersAdd={props.onFiltersAdd}
      />
      <AmountFilter
        onFiltersAdd={props.onFiltersAdd}
      />
      <ColumnFilterPayments
        onFiltersAdd={props.onFiltersAdd}
      />
      <div style={{ flexGrow: 1 }} />
      <Exporter
        onExport={props.onExport}
        rowCount={props.rowCount}
      />
    </div>
  )
}

ControlsConfigurationPayments.propTypes = {
  filters: PropTypes.array.isRequired,
  onFiltersAdd: PropTypes.func.isRequired,
  onFilterDelete: PropTypes.func.isRequired,
  onFilterDeleteAll: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  onSearchTextChange: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  rowCount: PropTypes.number,
}

export default ControlsConfigurationPayments
