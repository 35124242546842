import React from 'react'
import PropTypes from 'prop-types'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'

const GroupBuilderDialog = ({
  open,
  onClose,
  groupEditId,
  filterField,
  selectedFilterValues,
  filterOptions,
  savedGroups,
  onGroupUpsert,
}) => {
  if (!open || !filterField) return null

  const [groupName, setGroupName] = React.useState(() => {
    if (groupEditId) {
      const group = savedGroups.find(g => g.id === groupEditId)
      return group.name
    } else {
      return ''
    }
  })
  const [filterValues, setFilterValues] = React.useState(() => {
    // If this is an edit, get the group from savedGroups and use that as the values
    if (groupEditId) {
      const group = savedGroups.find(g => g.id === groupEditId)
      const itemValues = group.filter.itemValues
      return filterOptions.filter(o => itemValues.includes(o.id))
    }
    // If this is creation, use the selected items from props
    else {
      return selectedFilterValues
    }
  })
  const [filterValuesInput, setFilterValuesInput] = React.useState('')

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle>{groupEditId ? 'Edit Group' : `Create new ${filterField.name} Group`}</DialogTitle>
      <DialogContent
        sx={{ padding: theme => theme.spacing(1, 3, 0, 3)}}
      >
        <TextField
          autoFocus
          size='small'
          margin='dense'
          label='Group Name'
          type='text'
          fullWidth
          value={groupName}
          onChange={e => setGroupName(e.target.value)}
          error={groupName.length === 0}
        />

        <Autocomplete
          id='value-autocomplete'
          size='small'
          sx={{
            marginTop: theme => theme.spacing(2),
          }}
          multiple
          options={filterOptions.map(f => ({...f, type: 'item', group: 'All Values'})).sort((a, b) => -b.name.localeCompare(a.name))}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          groupBy={(option) => option.group}
          getOptionLabel={option => `${option.name}${option.group === 'All Values' && (filterField.id === 'product_id' || filterField.id === 'funnel_id' || filterField.id === 'integration_id') ? ` [${option.platform_id}]` : ''}`}
          filterOptions={createFilterOptions({
            matchFrom: 'any',
            stringify: (option) => `[${option.name} ${option.platform_id}]`
          })}
          value={filterValues}
          onChange={(_, option) => setFilterValues(option)}
          onInputChange={(_, value) => setFilterValuesInput(value)}
          inputValue={filterValuesInput}
          renderInput={(params) => (
            <TextField {...params}
              label={'Values'}
              variant='outlined'
              placeholder={filterField ? `Select ${filterField.name}` : 'Select filter to load values'}
              error={filterValues.length === 0}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.id} style={{ justifyContent: 'flex-start' }}>
              <Box width='100%' display='flex' flexDirection='row' alignItems='center'>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                  checkedIcon={<CheckBoxIcon fontSize='small' />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />

                <Typography variant='body2'>
                  {option.name}
                </Typography>

                {(option.type === 'item' && (filterField.id === 'product_id' || filterField.id === 'funnel_id' || filterField.id === 'integration_id')) && (
                  <>
                    <div style={{ flexGrow: 1 }} />
                    <Typography variant='caption'>
                      [{option.platform_id}]
                    </Typography>
                  </>
                )}
              </Box>
            </li>
          )}
          noOptionsText='No matches'
          autoHighlight
          openOnFocus
          fullWidth
        />

      </DialogContent>
      <DialogActions>
        <Button variant='text' onClick={onClose} color='error'>
          Cancel
        </Button>
        <Button
          variant='text'
          color='secondary'
          onClick={() => onGroupUpsert(groupEditId, groupName, filterValues)}
          disabled={filterValues.length === 0 || groupName.length === 0}
        >
          {groupEditId ? 'Save' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

GroupBuilderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  groupEditId: PropTypes.string,
  filterField: PropTypes.object,
  selectedFilterValues: PropTypes.array,
  filterOptions: PropTypes.array,
  savedGroups: PropTypes.array.isRequired,
  onGroupUpsert: PropTypes.func.isRequired,
}

export default GroupBuilderDialog