import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Alert from '@mui/material/Alert'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Snackbar from '@mui/material/Snackbar'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom'

import { UserContext } from '../../../contexts/UserContext'

import EnhancedTableHead from './TableHead'

import * as ROUTES from '../../../constants/routes'
import { formatNumber, getDisplayValueByFormat } from '../../../utils/helpers'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  paper: {
    width: '100%',
  },
  tableContainer: {
    maxHeight: props => props.filters.length > 0 ? 'calc(100vh - 290px)' : 'calc(100vh - 228px)'
  },
  tableActionButton: {
    padding: theme.spacing(1) / 2,
  },
  tableCellMinWidth: {
    minWidth: 125,
  },
  linearProgress: {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    position: 'absolute',
    width: '100%',
  },
  summaryTypography: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1, 2, 0, 2)
  },
  popover: {
    marginTop: theme.spacing(1),
  },
  listItemIconRootOverride: {
    minWidth: 42,
  },
  listItemSecondaryIconRootOverride: {
    minWidth: 24,
    width: 24,
    marginLeft: theme.spacing(2),
  },
  listItemTextApplyRootOverride: {
    paddingLeft: 42,
  },
  noRowsTableCell: {
    textAlign: 'center',
  },
  noRowsTypography: {
    color: theme.palette.text.secondary,
  },
  rowHover: {
    cursor: 'pointer',
  },
}))


const EnhancedTable = (props) => {
  const { filters, order, orderBy, page, rowsPerPage, searchText, setOrder, setOrderBy, setPage, setRowsPerPage, isLoading, isError, rows, rowCount, disablePagination, disableSorting, disableRowSelect } = props

  const classes = useStyles(props)
  const history = useHistory()
  const [columns] = React.useState('default')

  const handleRequestSort = (_, property) => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleRowClick = (row) => {
    if (disableRowSelect) return
    history.push(ROUTES.PEOPLE + `/${row.contact_id}`, {
      previousRoute: ROUTES.PAYMENTS,
      filters: JSON.parse(JSON.stringify(filters)),
      order,
      orderBy,
      page,
      rowsPerPage,
      searchText
    })
  }

  return (
    <Fragment>
      <div className={classes.root}>
        {isLoading && <LinearProgress className={classes.linearProgress} />}
        <Paper className={classes.paper}>
          {disablePagination ? null : (
            <TablePagination
              rowsPerPageOptions={[50]}
              component='div'
              count={rowCount}
              page={isLoading ? 0 : page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={() => {
                if (isLoading) return 'Loading...'
                const from = Math.min(page*rowsPerPage + 1, rowCount)
                const to = Math.min(from + rowsPerPage - 1, rowCount)
                return `${formatNumber(from, 0)}-${formatNumber(to, 0)} of ${formatNumber(rowCount, 0)} payments`
              }}
            />
          )}
          <TableContainer className={classes.tableContainer}>
            <Table
              aria-labelledby='tableTitle'
              size='small'
              aria-label='enhanced table'
              stickyHeader
            >
              <EnhancedTableHead
                columns={columns}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                disableSorting={disableSorting}
              />
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={17}>
                      <Skeleton animation={false} />
                    </TableCell>
                  </TableRow>
                ) : (rows.length === 0 ? (
                  <TableRow>
                    <TableCell className={classes.noRowsTableCell} colSpan={3}>
                      <Typography className={classes.noRowsTypography}>
                        No rows
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map(row =>
                    <EnhancedTableRow
                      key={row.id}
                      classes={classes}
                      columns={columns}
                      row={row}
                      filters={filters}
                      onRowClick={handleRowClick}
                      disableRowSelect={disableRowSelect}
                    />)
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
        open={isError}
        autoHideDuration={10000}
      >
        <Alert severity='error'>
          Error fetching table data. Please try again, and if problem continues, contact support.
        </Alert>
      </Snackbar>
    </Fragment>
  );
}

const EnhancedTableRow = (props) => {
  const { classes, columns, onRowClick, disableRowSelect } = props

  return (
    <TableRow
      onClick={() => onRowClick(props.row)}
      hover
      classes={{
        hover: disableRowSelect ? null : classes.rowHover
      }}
    >
      {(() => {
        switch(columns) {
          case 'default':
          default:
            return <TableCellsDefault {...props} />
        }
      })()}
    </TableRow>
  )
}

const TableCellsDefault = (props) => {
  const { classes, row } = props
  const userDoc = React.useContext(UserContext)
  return (
    <Fragment>
      <TableCell className={classes.tableCellMinWidth} align='left'>
        {moment.tz(row.created_at, TIMEZONE).format('M/D/YY, HH:mm')}
      </TableCell>
      <TableCell className={classes.tableCellMinWidth} align='left'>
        {row.email}
      </TableCell>
      <TableCell align='right'>
        {getDisplayValueByFormat(
          row.amount_cents / 100,
          'currency',
          {
            decimalCount: 2,
            currency: userDoc.currency,
          }
        )}
      </TableCell>
      <TableCell align='right'>
        {getDisplayValueByFormat(
          row.commission_amount_cents / 100,
          'currency',
          {
            decimalCount: 2,
            currency: userDoc.currency,
          }
        )}
      </TableCell>
      <TableCell className={classes.tableCellMinWidth} align='left'>
        {row.platform}
      </TableCell>
      <TableCell className={classes.tableCellMinWidth} align='left'>
        {row.funnel} [ID:{row.funnel_platform_id}]
      </TableCell>
      <TableCell className={classes.tableCellMinWidth} align='left'>
        {row.product} [ID:{row.product_platform_id}]
      </TableCell>
      <TableCell className={classes.tableCellMinWidth} align='left'>
        {row.productVariant} {row.productVariant_platform_id ? `[ID:${row.productVariant_platform_id}]` : null}
      </TableCell>
      <TableCell align='right'>
        {row.order_id}
      </TableCell>
      <TableCell align='left'>
        {row.type}
      </TableCell>
      <TableCell className={classes.tableCellMinWidth} align='left'>
        {row.utm_source}
      </TableCell>
      <TableCell className={classes.tableCellMinWidth} align='left'>
        {row.utm_medium}
      </TableCell>
      <TableCell className={classes.tableCellMinWidth} align='left'>
        {row.utm_campaign}
      </TableCell>
      <TableCell className={classes.tableCellMinWidth} align='left'>
        {row.utm_content}
      </TableCell>
      <TableCell className={classes.tableCellMinWidth} align='left'>
        {row.utm_term}
      </TableCell>
      <TableCell className={classes.tableCellMinWidth} align='left'>
        {row.affiliate_id_1}
      </TableCell>
      <TableCell className={classes.tableCellMinWidth} align='left'>
        {row.affiliate_id_2}
      </TableCell>
      <TableCell className={classes.tableCellMinWidth} align='left'>
        {row.source}
      </TableCell>
      <TableCell className={classes.tableCellMinWidth} align='left'>
        {row.integration} [ID:{row.integration_platform_id}]
      </TableCell>
    </Fragment>
  )
}

EnhancedTable.defaultProps = {
  filters: []
}

EnhancedTable.propTypes = {
  filters: PropTypes.array,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number,
  searchText: PropTypes.string,
  setOrder: PropTypes.func,
  setOrderBy: PropTypes.func,
  setPage: PropTypes.func,
  setRowsPerPage: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  rows: PropTypes.array.isRequired,
  rowCount: PropTypes.number,
  disablePagination: PropTypes.bool,
  disableSorting: PropTypes.bool,
  disableRowSelect: PropTypes.bool
}

export default EnhancedTable